import { ValuesOf } from "@services/type-utils";
import { DetectionShortDTO } from "./detection";
import { Entity } from "./entity";
import {
  InboundFlight,
  InboundFlightDTO,
  OutboundFlight,
  OutboundFlightDTO,
} from "./flightInfo";
import { TURN_STATE } from "@constants";
import { TurnaroundParamName, TurnaroundParams } from "./TurnaroundParams";

//TODO use schema from apiSpec
export type TurnaroundDetailDTO = {
  authorized: boolean;
  dedicated_airline: string | null;
  end_ts: number | null;
  id: string;
  inbound_flight: InboundFlightDTO | null;
  original_stand_id: string | null;
  outbound_flight: OutboundFlightDTO | null;
  params: TurnaroundParamsDTO;
  pobt: number | null;
  progress: number | null;
  pushback_speed_max: number | null;
  replays: { [key: string]: TurnaroundReplayDTO };
  stand_id: string;
  start_ts: number;
  detections: DetectionShortDTO[];
  state:
    | "pre_aircraft"
    | "aircraft_enters"
    | "aircraft"
    | "post_aircraft"
    | "finished"
    | null;
};

type TurnaroundParamsDTO = {
  aircraft_end_ts: number | null;
  aircraft_on_stand_end_ts: number | null;
  aircraft_on_stand_start_ts: number | null;
  aircraft_start_ts: number | null;
  predicted_aircraft_ready_ts: number | null;
  aldt: number | null;
  eibt: number | null;
  eobt: number | null;
  ltd: number | null;
  pobt: number | null;
  sibt: number | null;
  sobt: number | null;
};

type TurnaroundReplayDTO = {
  end_ts: number;
  speed: number;
  start_ts: number;
  url: string;
};

export type Replay = {
  endTs: number;
  speed: number;
  startTs: number;
  url: string;
};

export const EXTENDED_PARAMS_SYMBOL = Symbol(
  "Some turnaround params was replaced on client side by timestamps from detections"
);
export type ExtendedTurnaroundParams = TurnaroundParams & {
  [EXTENDED_PARAMS_SYMBOL]: TurnaroundParamName[];
};

export interface Turnaround extends Entity {
  start: number;
  end: number | null;
  authorized: boolean;
  replays: Record<string, Replay>;
  inboundFlight: InboundFlight | null;
  outboundFlight: OutboundFlight | null;
  progress: number | null;
  standId: string;
  originalStandId?: string;
  dedicatedAirline: string | null;
  params: TurnaroundParams;
  /**
   * "aircraft" - an aircraft is on the stand
   */
  state: TTurnState | null;
  aircraftType: string | null;
}

type TTurnState = ValuesOf<typeof TURN_STATE>;

export const createTurnaroundParams = (
  payload: Partial<TurnaroundParams> = {}
): TurnaroundParams => ({
  aircraft_start_ts: null,
  aircraft_end_ts: null,
  aircraft_on_stand_start_ts: null,
  aircraft_on_stand_end_ts: null,
  aircraft_or_pushback_ts: null,
  aircraft_ready_ts: null,
  predicted_aircraft_ready_ts: null,
  flight_ardt: null,
  initial_eobt: null,
  aldt: null,
  eibt: null,
  eobt: null,
  ltd: null,
  pobt: null,
  sibt: null,
  sobt: null,
  tobt: null,
  aobt: null,
  aibt: null,
  tsat: null,
  asat: null,
  asrt: null,
  apbg: null,
  ctot: null,
  cobt: null,
  ...payload,
});

export const getTurnaroundMock = (
  payload: Partial<Turnaround> = {}
): Turnaround => ({
  id: "1",
  start: 0,
  end: 0,
  authorized: true,
  replays: {},
  inboundFlight: null,
  outboundFlight: null,
  progress: null,
  standId: "1",
  dedicatedAirline: null,
  params: createTurnaroundParams(),
  state: null,
  aircraftType: null,
  ...payload,
});
